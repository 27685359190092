import { Provider } from "../../types";

const USER_NAME_VALIDATION_RULES = /^[^-\s](?!.* {2})[a-zA-ZÄäÖöÜüß\-'/ .]{1,30}$/;
const AREA_CODE_VALIDATION_RULES = /^\d{2,6}$/;
const PHONE_NUMBER_VALIDATION_RULES = /[-\\/+\d]{3,15}$/;
const EMAIL_VALIDATION_RULES = /^(?![-.])(?!.*--)(?!.*?\.\.)[a-zA-Z0-9!$%&+-/=_]+(?![-.])@(?![-])[A-Za-z0-9-]+(?![-])([.][A-Za-z]+([-]?[A-Za-z]+)+)+$/;
export const PASSWORD_VALIDATION_RULES = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&'*+-/=?^_`{|}~.,;:()@])[A-Za-z\d!#$%&'*+-/=?^_`{|}~.,;:()@]{8,}$/;
const IBAN_VALIDATION_RULES = /[A-Z]{2}[0-9]{2,28}/;
const ACCOUNT_HOLDER_RULES = /^[^-\s](?!.* {2})[a-zA-ZÄäÖöÜüß\-'/ .]{1,50}$/;
const METER_NUMBER_RULES = /^[a-zA-Z0-9\-_\\/\s]{1,18}$/;

const ERROR_MESSAGE_FIRST_NAME = "Bitte gib einen gültigen Vornamen ein";
const ERROR_MESSAGE_LAST_NAME = "Bitte gib einen gültigen Nachnamen ein";
const ERROR_MESSAGE_AREA_CODE =
  "Deine Vorwahl sollte zwischen 2 und 6 Ziffern haben";
const ERROR_MESSAGE_PHONE_NUMBER =
  "Deine Telefonnummer sollte zwischen 3 und 15 Ziffern haben.";
const ERROR_MESSAGE_EMAIL =
  "Diese E-Mail ist entweder ungültig oder wird bereits verwendet.";
const ERROR_MESSAGE_PASSWORD =
  "Bitte gib ein gültiges Passwort ein. Das Passwort muss aus mindestens 8 Zeichen bestehen und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen (!#$%&'*+-/=?^_`{|}~.,;:()@) enthalten.";
const ERROR_MESSAGE_CONFIRM_PASSWORD =
  "Passwort und Passwort bestätigen sollten identische Einträge haben";
const ERROR_MESSAGE_IBAN = "Bitte gib eine gültige IBAN ein";
const ERROR_MESSAGE_PROMO_CODE = "Dein Aktionscode ist leider ungültig.";
const ERROR_MESSAGE_ACCOUNT_HOLDER = "Bitte gib einen gültigen Namen ein";
const ERROR_MESSAGE_ACCOUNT_HOLDER_FN = "Bitte gib einen gültigen Vornamen ein";
const ERROR_MESSAGE_ACCOUNT_HOLDER_LN =
  "Bitte gib einen gültigen Nachnamen ein";
const ERROR_MESSAGE_METER_NUMBER = "Wir benötigen deine Zählernummer.";
const ERROR_MESSAGE_PREVIOUS_SUPPLIER =
  "Bitte gib einen gültigen Namen für deinen alten Anbieter ein";

const validate = (
  errorMessage: string,
  validationRegExp: RegExp,
  notRequired: boolean
) => {
  return {
    match: (value: string) => {
      if (notRequired && value === "") {
        return true;
      }
      const isValid = validationRegExp.test(value);
      if (isValid) {
        return true;
      }
      return errorMessage;
    },
  };
};

export const validateConfirmPassword = (password: string) => {
  return {
    match: (value: string) => {
      const isValid = PASSWORD_VALIDATION_RULES.test(value);
      if (value !== password) {
        return ERROR_MESSAGE_CONFIRM_PASSWORD;
      }
      if (!isValid) {
        return ERROR_MESSAGE_PASSWORD;
      }
      return true;
    },
  };
};

export const validateIBAN = (isIbanBank: boolean) => {
  return {
    match: (value: string) => {
      const isValid = IBAN_VALIDATION_RULES.test(value.replace(/\s/g, ""));
      if (!isIbanBank) {
        return ERROR_MESSAGE_IBAN;
      }
      if (!isValid) {
        return ERROR_MESSAGE_IBAN;
      }
      return true;
    },
  };
};

export const validatePromoCode = (isPromoCode: boolean) => {
  return {
    match: (value: string) => {
      if (value.length === 0) {
        return true;
      }
      if (!isPromoCode) {
        return ERROR_MESSAGE_PROMO_CODE;
      }
      return true;
    },
  };
};

export const validateEmail = (isEmail: boolean) => {
  return {
    match: (value: string) => {
      const isValid = EMAIL_VALIDATION_RULES.test(value);
      if (isEmail) {
        return ERROR_MESSAGE_EMAIL;
      }
      if (!isValid) {
        return ERROR_MESSAGE_EMAIL;
      }
      return true;
    },
  };
};

export const validateBillingEmail = () => {
  return {
    match: (value: string) => {
      const isValid = EMAIL_VALIDATION_RULES.test(value);
      if (!isValid) {
        return ERROR_MESSAGE_EMAIL;
      }
      return true;
    },
  };
};

export const validatePreviousSupplier = (providers: Provider[]) => {
  return {
    match: (value: string) => {
      const providersNameList = providers.map((provider) => {
        return provider.name.toLowerCase();
      });
      if (!providersNameList.includes(value.toLowerCase())) {
        return ERROR_MESSAGE_PREVIOUS_SUPPLIER;
      }
      return true;
    },
  };
};

export const validateAreaCode = validate(
  ERROR_MESSAGE_AREA_CODE,
  AREA_CODE_VALIDATION_RULES,
  false
);

export const validatePhoneNumber = validate(
  ERROR_MESSAGE_PHONE_NUMBER,
  PHONE_NUMBER_VALIDATION_RULES,
  false
);

export const validatePassword = validate(
  ERROR_MESSAGE_PASSWORD,
  PASSWORD_VALIDATION_RULES,
  false
);

export const validateFirstName = validate(
  ERROR_MESSAGE_FIRST_NAME,
  USER_NAME_VALIDATION_RULES,
  false
);

export const validateLastName = validate(
  ERROR_MESSAGE_LAST_NAME,
  USER_NAME_VALIDATION_RULES,
  false
);

export const validateAccountHolder = validate(
  ERROR_MESSAGE_ACCOUNT_HOLDER,
  ACCOUNT_HOLDER_RULES,
  true
);

export const validateAccountHolderFN = validate(
  ERROR_MESSAGE_ACCOUNT_HOLDER_FN,
  ACCOUNT_HOLDER_RULES,
  true
);

export const validateAccountHolderLN = validate(
  ERROR_MESSAGE_ACCOUNT_HOLDER_LN,
  ACCOUNT_HOLDER_RULES,
  true
);

export const validateMeterNumber = validate(
  ERROR_MESSAGE_METER_NUMBER,
  METER_NUMBER_RULES,
  false
);
